import { toLower } from "lodash";
import { CandleInfoType } from "../components/TradingViewChart/Components/TradingView/TradingView";
import { intervarMasterArr } from "../constants/chart-interval";
const WBNB_ADDRESS = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"
export const convertToChartData = (data: any, type: any) => {
  const res =
    type === CandleInfoType.USD
      ? {
          time: parseInt(data.timestamp) * 1000,
          close: parseFloat(data.closeUsd),
          open: parseFloat(data.openUsd),
          high: parseFloat(data.highUsd),
          low: parseFloat(data.lowUsd),
          volume: parseFloat(data.volumeUsd),
          id: data.id
        }
      : {
          time: parseInt(data.timestamp) * 1000,
          close: parseFloat(data.close),
          open: parseFloat(data.open),
          high: parseFloat(data.high),
          low: parseFloat(data.low),
          volume: parseFloat(data.volume),
          id: data.id
        };
  return res;
};
export const getResolution = (key: string) => {
  let result = '1';
  intervarMasterArr.forEach((row) => {
    row.row.forEach((item) => {
      if (item.key === key) {
        result = item.value;
      }
    })
  });
  return result;
}
export const getContractAddress = (address: string) => {
  return `https://bscscan.com/token/${address}`;
}

export const formatPoolInfo = (pool: any, token: string) => {
  if(!pool) return;

  const tokenAddress = token === 'bur' ? process.env.REACT_APP_BUR_ADDRESS : process.env.REACT_APP_BUNI_ADDRESS;
  const buniToken = pool.tokens.length && pool.tokens.find((token:any) => token.address.toLowerCase() === (tokenAddress || '').toLowerCase());
  const wbnbToken = pool.tokens.length && pool.tokens.find((token:any) => token.address == WBNB_ADDRESS);
  console.log('POOL', wbnbToken,  {
    ...pool,
    wbnbToken: wbnbToken?.balance || 0,
    buniToken: buniToken?.balance || 0,
  });

  return {
    ...pool,
    wbnbToken: wbnbToken?.balance || 0,
    buniToken: buniToken?.balance || 0,
  }
}
