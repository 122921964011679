import React from "react";
import "./Home.scss";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Chart from "../../components/TradingViewChart/Components/Chart";
import TransactionTable from "../../components/TradingViewChart/Components/TransactionTable/TransactionTable";
import CommonInfo from "../../components/TradingViewChart/Components/CommonInfo/CommonInfo";
import {  useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
const Home: React.FC = () => {
  const { search } = useLocation();
  const values = queryString.parse(search)
  const handle = useFullScreenHandle();
  //@ts-ignore
  const coin = values.coin;
  const tokenName = !!coin ? coin == process.env.REACT_APP_BUR_ADDRESS ? 'bur' : 'buni' :  'buni';

  return (
    <div className="wrapper">
      <div className="info-block">
        <CommonInfo token={tokenName}/>
        <div className="chart-block">
          <FullScreen handle={handle}>
            <Chart
              handleFullscreen={!handle.active ? handle.enter : handle.exit}
              isFullScreen={handle.active}
              token={tokenName}
            ></Chart>
          </FullScreen>
        </div>

      </div>
      <TransactionTable token={tokenName}/>
    </div>
  );
};
export default Home;
