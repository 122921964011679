/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames/bind';
import React from 'react';
import style from '../../styles/Chart.module.scss';
import TradingView from '../TradingView/TradingView';
interface Props {
  className?: string;
  handleFullscreen: any;
  isFullScreen: boolean;
  token: string;
}

const cx = classNames.bind(style);

const Chart: React.FC<Props> = (props) => {
  return (
    <div className={cx('parent', props.isFullScreen ? 'full-screen' : '')}>
      <TradingView
        isFullScreen={props.isFullScreen}
        containerId="tv_chart_container"
        theme={'Dark'}
        handleFullscreen={props.handleFullscreen}
        token={props.token}
      />
    </div>
  );
};
export default Chart;
