
import { ApolloClient as apolloClientDefault, InMemoryCache } from '@apollo/client';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
export const ApolloClient = (token: string) => {
    // @ts-ignore
    if (window[`apolloClient-${token}`]) {
        // @ts-ignore
        return window[`apolloClient-${token}`];
    }
    const subgraphUrl = (!token || (token && token.toLowerCase() === 'buni') ? process.env.REACT_APP_SUBGRAPH_URL : process.env.REACT_APP_SUBGRAPH_URL_BUR) || 'https://api.thegraph.com/subgraphs/name/bunicorndefi/bunicorn-dextool-data';
    const subcriptionUrl = (!token || (token && token.toLowerCase() === 'buni') ? process.env.REACT_APP_SUBGRAPH_ENDPOINT : process.env.REACT_APP_SUBGRAPH_ENDPOINT_BUR) || 'wss://api.thegraph.com/subgraphs/name/bunicorndefi/bunicorn-dextool-data';

    const httpLink = new HttpLink({
        uri: subgraphUrl
    });

    const wsLink = new WebSocketLink({
        uri: subcriptionUrl,
        options: {
            reconnect: true
        }
    });

    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    const splitLink = split(
      ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
      },
      wsLink,
      httpLink,
    );

    // @ts-ignore
    window[`apolloClient-${token}`] = new apolloClientDefault({
        link: splitLink,
        cache: new InMemoryCache()
    });
    // @ts-ignore
    return window[`apolloClient-${token}`];
}
