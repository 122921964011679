import {
  areaChart,
  barsChart,
  baseLine,
  candleChart,
  heikinAshi,
  hollowCandle,
  lineChart,
} from '../assets/icon';
export const intervarMasterArr = [
  {
    row: [
      {
        key: 'Time',
        value: '1',
        status: false,
      },
      {
        key: '1m',
        value: '1',
        status: false,
      },
      {
        key: '3m',
        value: '3',
        status: false,
      },
      {
        key: '5m',
        value: '5',
        status: false,
      },
    ],
  },
  {
    row: [
      {
        key: '15m',
        value: '15',
        status: false,
      },
      {
        key: '30m',
        value: '30',
        status: false,
      },
      {
        key: '1h',
        value: '60',
        status: false,
      },
      {
        key: '2h',
        value: '120',
        status: false,
      },
    ],
  },
  {
    row: [
      {
        key: '4h',
        value: '240',
        status: false,
      },
      {
        key: '6h',
        value: '360',
        status: false,
      },
      {
        key: '8h',
        value: '480',
        status: false,
      },
      {
        key: '12h',
        value: '720',
        status: false,
      },
    ],
  },
  {
    row: [
      {
        key: '1D',
        value: '1440',
        status: false,
      },
      {
        key: '3D',
        value: '4320',
        status: false,
      },
      {
        key: '1W',
        value: '10080',
        status: false,
      },
      {
        key: '1M',
        value: '43200',
        status: false,
      },
    ],
  },
];

export const CHART_TYPE = [
  {
    type_number: 0,
    type_name: 'Bars',
    src_type: barsChart,
  },
  {
    type_number: 1,
    type_name: 'Candles',
    src_type: candleChart,
  },
  {
    type_number: 2,
    type_name: 'Line',
    src_type: lineChart,
  },
  {
    type_number: 3,
    type_name: 'Area',
    src_type: areaChart,
  },
  // {
  //   type_number: 4,
  //   type_name: 'Renko',
  //   src_type: renko,
  // },
  // {
  //   type_number: 5,
  //   type_name: 'Kagi',
  //   src_type: kagi,
  // },
  // {
  //   type_number: 6,
  //   type_name: 'Pnf',
  //   src_type: pnf,
  // },
  // {
  //   type_number: 7,
  //   type_name: 'Pb',
  //   src_type: chartType,
  // },
  {
    type_number: 8,
    type_name: 'Heiken ashi',
    src_type: heikinAshi,
  },
  {
    type_number: 9,
    type_name: 'Hollow candles',
    src_type: hollowCandle,
  },
  {
    type_number: 10,
    type_name: 'Baseline',
    src_type: baseLine,
  },
];
