// @ts-nocheck
import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";
import NabarTradingView from "../NabarTradingView";
import style from "../../styles/Chart.module.scss";
import {
  ThemeName,
  TradingTerminalWidgetOptions,
  widget,
} from "../../../../charting_library/charting_library.min";
import Datafeed from "../Chart/Datafeed";
import {
  getCandleChartInfo,
  getBiggestSmallerTimestamp,
} from "../../../../services/swap";
import * as _ from "lodash";
import { gql } from "@apollo/client";
import { convertToChartData } from "../../../../helpers";
import { CHART_TYPE, intervarMasterArr } from "../../../../constants/chart-interval";
import { ApolloClient } from "../../../../apolloClient";
interface obj {
  [key: string]: boolean | number | string;
}
interface Props {
  theme?: ThemeName;
  containerId: string;
  libraryPath?: string;
  chartsStorageUrl?: string;
  chartsStorageApiVersion?: "1.0" | "1.1";
  clientId?: string;
  userId?: string;
  fullscreen?: boolean;
  autosize?: boolean;
  studiesOverrides?: obj;
  className?: string;
  overrides?: obj;
  handleFullscreen: any;
  isFullScreen: boolean;
  token:string;
}

interface Candle {
  close: number;
  high: number;
  low: number;
  time: number;
  open: number;
  volume: number;
}

const cx = classNames.bind(style);
const configurationData = {
  supports_search: true,
  supports_marks: true,
  supported_resolutions: [
    "1",
    "3",
    "5",
    "15",
    "30",
    "60",
    "120",
    "240",
    "360",
    "480",
    "720",
    "480",
    "1D",
    "3D",
    "1W",
    "1M",
  ],
};
export enum CandleInfoType {
  USD = "BUNI/USD",
  BNB = `BUNI/${process.env.REACT_APP_BNB_SYMBOL}`,
}
export enum BURCandleInfoType {
  USD = "BUR/USD",
  BNB = `BUR/${process.env.REACT_APP_BNB_SYMBOL}`,
}
const TradingView: React.FC<Props> = (props) => {
  let tradingViewChart: any = {};
  let lastCandle: Candle = {} as Candle;
  let jsonLastCandle = "";
  let chartRealtimeCallback: (candle: Candle) => void;
  const [interval, setInterval] = useState("1440");
  const [candleInfoType, setCandleInfoType] = useState(CandleInfoType.USD);
  const apolloClient = ApolloClient(props.token);
  const listDisable = [
    "header_widget",
    "display_market_status",
    "timeframes_toolbar",
    "edit_buttons_in_legend",
    "volume_force_overlay",
  ];
  const [disableLeftToolbars, setDisableLeftToolbars] = useState(listDisable);
  const round = (n: number, base: number) => {
    return Math.floor(n / base) * base;
  };
  //TODO interval may be a string: 1D, W
  const intervalInSeconds = Number(interval) * 60;
  const intervalInMilliseconds = intervalInSeconds * 1000;
  const onReady = (callback: any) => {
    setTimeout(() => callback(configurationData));
  };
  async function extractBars(startTime: number, endTime: number, resolution: number, bars: any) {
    const res = await getBiggestSmallerTimestamp(props.token, bars[bars.length - 1].time / 1000);
    let lastPrice = (res && !!res.length && res[0].closeUsd) || 0;
    let sTime = Math.floor(startTime / resolution) * resolution;
    let eTime = Math.floor(endTime / resolution) * resolution;
    const newBars = _.groupBy(bars, 'time');
    const result = [];
    for (let time = sTime; time <= eTime; time += resolution) {
      if (newBars[time]) {
        const bar = newBars[time][0];
        bar['open'] = lastPrice;
        bar['time'] = time;
        result.push(bar);
        lastPrice = newBars[time][0]['close'];
      } else {
        result.push({
          'low': lastPrice,
          'high': lastPrice,
          'open': lastPrice,
          'close': lastPrice,
          'volume': 0,
          'time': time,
        });
      }
    }
    return result;
  }
  const groupData = (bars: [], resolution: number) => {
    if (!bars.length) {
      return;
    }
    let result = [];
    let group = {};
    for (let i = 0; i < bars.length; i++) {
      let timestamp = Math.floor(bars[i].time / resolution) * resolution;
      let tmp = group[timestamp] || [];
      tmp.push(bars[i]);
      group[timestamp] = tmp;
    }
    for (const property in group) {
      const lowArr = Math.min(...group[property].map((data) => data.low));
      const highArr = Math.max(...group[property].map((data) => data.high));
      const newObj = {
        low: lowArr,
        high: highArr,
        open: group[property][0].open,
        close: group[property][group[property].length - 1].close,
        volume: group[property].reduce((acc, item) => acc + item.volume, 0),
        time: parseInt(property),
      };
      result.push(newObj);
    }
    return result;
  };

  const getBars = async (
    symbolInfo: any,
    resolution: string,
    from: number,
    to: number,
    onHistoryCallback: any,
    onErrorCallback: any,
  ) => {
    let convertedResolution = resolution;
    if (resolution === '1D' || resolution === 'D') {
      convertedResolution = 1440;
    }
    if (resolution === '1W' || resolution === 'W') {
      convertedResolution = 10080;
    }
    if (resolution === '1M' || resolution === 'M') {
      convertedResolution = 43200;
    }
    try {
      const data = [];
      let lastData = await getCandleChartInfo(props.token, from, to, resolution);
      if (lastData.length > 0) {
        data.push(...lastData);
      }
      let newTo = data[data.length - 1].timestamp;
      while (newTo > from && lastData?.length > 0) {
        lastData = await getCandleChartInfo(props.token, from, newTo, resolution);
        if (lastData?.length > 0) {
          data.push(...lastData);
          newTo = lastData[lastData.length - 1].timestamp;
        }
      }
      const bars: any = data.map((bar: any) => convertToChartData(bar, candleInfoType));
      const startTime = round(from, intervalInSeconds);
      const endTime = round(to, intervalInSeconds);
      const result = await extractBars(startTime * 1000, endTime * 1000, convertedResolution * 60 * 1000, groupData(bars, convertedResolution * 60 * 1000));
      if (!!result.length) {
        lastCandle = result[result.length - 1];
      }
      onHistoryCallback(result, { noData: result.length === 0 });

    } catch (error) {
      onErrorCallback(error);
    }
  };
  enum SYMBOL_TYPE {
    stock = 'stock',
    bitcoin = 'bitcoin',
  }
  const resolveSymbol = async (
    symbolName: any,
    onSymbolResolvedCallback: any,
    onResolveErrorCallback: any
  ) => {
    const symbolInfo = {
      ticker: getCandleInfoType(props.token, candleInfoType),
      name: getCandleInfoType(props.token, candleInfoType),
      description: getCandleInfoType(props.token, candleInfoType),
      type: SYMBOL_TYPE.bitcoin,
      session: "24x7",
      minmov: 1,
      pricescale: 1000,
      has_intraday: true,
      has_weekly_and_monthly: true,
      intraday_multipliers: ["1", "60"],
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 0,
    };
    onSymbolResolvedCallback(symbolInfo);
  };

  const addTradeToLastCandle = (trade: any) => {
    const resolution = interval * 60 * 1000;
    const tradeTime = Math.floor(trade.time / resolution) * resolution;

    if (tradeTime >= lastCandle?.time + resolution) {
      const newCandle: Candle = {
        open: lastCandle.close,
        close: trade.close,
        high: trade.high,
        low: trade.low,
        time: tradeTime,
        volume: trade.volume,
      };
      lastCandle = newCandle;
    } else {
      lastCandle.low = Math.min(trade.low, lastCandle.low);
      lastCandle.high = Math.max(trade.high, lastCandle.high);
      lastCandle.close = Number(trade.close);
      lastCandle.volume = lastCandle.volume + trade.volume;
    }
    chartRealtimeCallback(lastCandle);
  };

  const subscribeBars = (
    symbolInfo: any,
    resolution: any,
    onRealtimeCallback: any
  ) => {
    chartRealtimeCallback = onRealtimeCallback;

    try {
      apolloClient
        .subscribe({
          query: gql`
            subscription onNewItem {
              candles(first: 1, orderBy: timestamp, orderDirection: desc) {
                id
                high
                low
                open
                close
                volume
                highUsd
                lowUsd
                openUsd
                closeUsd
                volumeUsd
                timestamp
              }
            }
          `,
          variables: {},
        })
        .subscribe({
          next(data:any) {
            const res =
              data?.data.candles &&
              data?.data.candles.length &&
              data?.data.candles[0];
            if (res) {
              if(JSON.stringify(res) !== jsonLastCandle) {
                addTradeToLastCandle(convertToChartData(res, candleInfoType));
                jsonLastCandle = JSON.stringify(res);
              }
            }
            // Notify your application with the new arrived data
          },
        });
    } catch (e) {
      console.error("xxxxx", e.message);
    }
  };

  const createEmptyCandleIfNeeded = () => {
    const lastCandleEndTime = lastCandle.time + intervalInMilliseconds;
    const tradePrice = lastCandle.close;
    const tradeTime = round(Date.now(), intervalInMilliseconds);
    if (tradeTime >= lastCandleEndTime) {
      const newCandle: Candle = {
        open: tradePrice,
        close: tradePrice,
        high: tradePrice,
        low: tradePrice,
        time: tradeTime,
        volume: 0,
      };
      lastCandle = newCandle;
    }
    if (chartRealtimeCallback) {
      chartRealtimeCallback(lastCandle);
    }
  };

  useEffect(() => {
    const interval = window.setInterval(() => {
      createEmptyCandleIfNeeded();
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  const datafeed = Datafeed;
  datafeed.onReady = onReady;
  datafeed.getBars = getBars;
  datafeed.subscribeBars = subscribeBars;
  datafeed.resolveSymbol = resolveSymbol;
  const getIntervalChart = (intervalValue: any) => {
    let result = '1m';
    if (Number.parseInt(intervalValue) <= 720) {
      result = intervalValue;
    } else {
      intervarMasterArr.forEach((row) => {
        row.row.forEach((item) => {
          if (item.value === intervalValue) {
            result = item.key;
          }
        })
      });
    }
    return result;
  }
  useEffect(() => {
    const widgetOptions: TradingTerminalWidgetOptions = {
      symbol: getCandleInfoType(props.token, candleInfoType),
      datafeed: datafeed,
      timezone: 'Asia/Bangkok',
      interval: getIntervalChart(interval),
      container_id: props.containerId,
      library_path: props.libraryPath,
      locale: "en",
      disabled_features: disableLeftToolbars,
      enabled_features: ["study_templates"],
      custom_css_url: "/",
      overrides: {
        "paneProperties.topMargin": 15,
        "scalesProperties.showStudyLastValue": true,
        "mainSeriesProperties.candleStyle.upColor": "#3c8279",
        "mainSeriesProperties.candleStyle.downColor": "#b64444",
        "mainSeriesProperties.candleStyle.drawWick": true,
        "mainSeriesProperties.candleStyle.drawBorder": true,
        "mainSeriesProperties.candleStyle.borderColor": "#378658",
        "mainSeriesProperties.candleStyle.borderUpColor": "#3c8279",
        "mainSeriesProperties.candleStyle.borderDownColor": "#b64444",
        "mainSeriesProperties.candleStyle.wickUpColor": "#3c8279",
        "mainSeriesProperties.candleStyle.wickDownColor": "#b64444",
        "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,
        "mainSeriesProperties.minTick": "100000000",
        'paneProperties.horzGridProperties.color': 'rgb(27, 29, 38)',
        'paneProperties.vertGridProperties.color': 'rgb(27, 29, 38)',
        "scalesProperties.lineColor": "#969AAC",
      },
      charts_storage_url: props.chartsStorageUrl,
      charts_storage_api_version: props.chartsStorageApiVersion,
      client_id: props.clientId,
      user_id: props.userId,
      autosize: props.autosize,
      studies_overrides: props.studiesOverrides,
      theme: props.theme,
    };
    tradingViewChart = new widget(widgetOptions);
    return () => {
      tradingViewChart.onChartReady(() => { });
    };
  }, [tradingViewChart]);

  const setChartType = (chartTypeNumber: number) => {
    tradingViewChart.chart().setChartType(chartTypeNumber);
  };

  const selectInterval = (value: any) => {
    setInterval(value);
    tradingViewChart.chart().setResolution(value, null);
  };

  const openIndicatorPopup = () => {
    tradingViewChart.chart().executeActionById("insertIndicator");
  };

  const disableLeftToolBar = (leftToolbar: string) => {
    setDisableLeftToolbars((disableLeftToolBar) => [
      ...disableLeftToolBar,
      leftToolbar,
    ]);
  };

  const removeDisableLefToolbar = (leftToolbar: string) => {
    setDisableLeftToolbars(
      disableLeftToolbars.filter((item) => item !== leftToolbar)
    );
  };
  const getNavCandleInfoType = (token: string, candleInfoType: CandleInfoType) => {
    if (candleInfoType === CandleInfoType.BNB) {
      if(token ==='buni') { return CandleInfoType.USD; } else { return BURCandleInfoType.USD};
    } else {
      if(token ==='buni') { return CandleInfoType.BNB; } else { return BURCandleInfoType.BNB};
    }
  }
  const getCandleInfoType = (token: string, candleInfoType: CandleInfoType) => {
    if (candleInfoType === CandleInfoType.BNB) {
      if(token ==='buni') { return CandleInfoType.BNB; } else { return BURCandleInfoType.BNB};
    } else {
      if(token ==='buni') { return CandleInfoType.USD; } else { return BURCandleInfoType.USD};
    }
  }
  return (
    <div className={cx("tradingview-parent")}>
      <NabarTradingView
        onSelectInterval={selectInterval}
        openIndicatorPopup={openIndicatorPopup}
        setChartType={setChartType}
        disableLeftToolBar={disableLeftToolBar}
        removeDisableLefToolbar={removeDisableLefToolbar}
        containerId={props.containerId}
        currentInterval={interval}
        candleInfoType={getNavCandleInfoType(props.token,candleInfoType)}
        setCandleInfoType={() =>
          setCandleInfoType(
            candleInfoType === CandleInfoType.USD
              ? CandleInfoType.BNB
              : CandleInfoType.USD
          )
        }
        handleFullscreen={props.handleFullscreen}
        isFullScreen={props.isFullScreen}
      />
      <div id={props.containerId} className={cx("Chart", props.isFullScreen ? 'full-screen' : '')}></div>
    </div>
  );
};

TradingView.defaultProps = {
  symbol: 'BUNI/WBNB',
  containerId: "tv_chart_container",
  libraryPath: "../../charting_library/",
  chartsStorageApiVersion: "1.1",
  clientId: "tradingview.com",
  userId: "public_user_id",
  fullscreen: true,
  autosize: true,
  studiesOverrides: {
    "volume.volume.color.0": "rgba(247, 73, 64, 0.19)",
    "volume.volume.color.1": "rgba(41, 155, 130, 0.2)",
    "volume.volume.transparency": 1,
    "volume.volume ma.color": "#f74940",
    "volume.volume ma.transparency": 0,
    "volume.volume ma.linewidth": 1,
    "volume.volume ma.plottype": "line",
    "volume.show ma": true,
  },
  theme: "Dark",
};
export default TradingView;
