// @ts-nocheck
import { Interface } from '@ethersproject/abi';
import { Contract } from '@ethersproject/contracts';
import { getAddress } from '@ethersproject/address';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import config from '../config';
import { BNB_KEY } from '../utils/helpers';
import { abi as multicallAbi } from '../helpers/abi/Multicall.json';
import numeral from 'numeral';
import BigNumber from 'bignumber.js';

BigNumber.config({ EXPONENTIAL_AT: 30, DECIMAL_PLACES: 30 });

const MULTICALL = config.addresses.multicall;

export async function multicall(provider, abi, calls) {
  try {
    const multi = new Contract(MULTICALL, multicallAbi, provider);
    const itf = new Interface(abi);
    const calldata = calls.map(call => {
      return [
        call.address.toLowerCase(),
        itf.encodeFunctionData(call.name, call.params)
      ];
    });
    const { returnData } = await multi.aggregate(calldata);
    const res = returnData.map((call, i) =>
      itf.decodeFunctionResult(calls[i].name, call)
    );

    return res;
  } catch (e) {
    console.error('[multicall]', e.message);
  }
}

export async function subgraphRequest(url, query) {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ query: jsonToGraphQLQuery({ query }) })
  });
  const { data } = await res.json();
  return data || {};
}

export function getTokenLogoUrl(address: string): string | null {
  if (address === BNB_KEY) {
    address = config.addresses.weth;
  }
  let metadata = config.tokens[address];
  if (metadata) {
    return metadata.logoURI;
  }
  address = getAddress(address);
  metadata = config.tokens[address];
  if (!metadata) {
    return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`;
  }
  return metadata.logoURI;
}

export function formatNumber(number, key) {
  if (number === 0) return '-';
  if (number < 1e-8 && !key) return '< 0.00000001';

  let format = '0.[000]';
  if (number > 1000) format = '0.[0]a';
  if (number < 1) format = '0.[0000]';

  if (key === 'long') {
    format = '0,000.[00]';
    if (number > 1000) format = '0.[0]a';
    if (number < 1) {
      const fixedNumber = new BigNumber(number).toFixed(8, BigNumber.ROUND_DOWN);
      if(new BigNumber(fixedNumber).eq(0)) {return '< 0.00000001';}
      return new BigNumber(fixedNumber).toString();
    }
    if (number < 1e-8) return '< 0.00000001';
  }

  if (key === 'usd') {
    format = '$(0.[00])';
    if (number > 1000) format = '$(0.[0]a)';
    if (number < 1) format = '$(0.[0000])';
    if (number < 1e-4) return '< $0.0001';
  }
  if (key === 'usd-long' && number <= 1e-8) return '< 0.00000001';

  if (key === 'usd-long') {
    format = '$(0,000.[00])';
    if (number < 1) format = '$(0.[00000000])';
  }

  if (key === 'percent') format = '(0.[00])%';
  if (key === 'percent-short') format = '(0)%';

  if (number < 0.0000000001 && key === 'percent') {
    return '< 0.0000000001%';
  }

  if (number < 0.0001 && !key) {
    const fixedNumber = new BigNumber(number).toFixed(8, 1);
    return new BigNumber(fixedNumber).toString();
  }
  return numeral(number)
    .format(format)
    .toUpperCase();
}

export function shortenAddress(str = '') {
  return str ? `${str.slice(0, 6)}...${str.slice(str.length - 4)}` : str;
}

export function isPriceIncreasing(previous, current) {
  if (previous < current) {
    let percent= parseFloat((current/previous - 1)* 100).toFixed(2);
    return ('+' + percent + '%');
  } else if (previous == current ) {
    return '--%';
  } else {
    let percent = parseFloat((1 - current/previous)* 100).toFixed(2);
    return ('-' + percent + '%');
  }
}

export function currentTimestampInSec() {
  return Math.floor(Date.now() / 1000);
}
