// @ts-ignore
const requireFile = require.context('./', true, /[\w-]+\.json$/);

export default Object.fromEntries(
  requireFile
    .keys()
    .map((fileName: any) => [
      fileName.replace('./', '').replace('.json', ''),
      requireFile(fileName).abi
    ])
);
