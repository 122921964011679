import React, { useEffect, useState, useRef, useMemo } from "react";
import PairIcon from "../../../../assets/logo.svg";
import BurIcon from "../../../../assets/bur-icon.png";
import LinkIcon from "../../../../assets/link-icon.svg";
import ArrowUp from "../../../../assets/arrow-up.svg";
import ArrowDown from "../../../../assets/arrow-down.svg";
import {
  get24hCandleCharts,
  getPriceUsdAtTime,
} from "../../../../services/swap";
import BigNumber from "bignumber.js";
import {
  shortenAddress,
  formatNumber,
  currentTimestampInSec,
} from "../../../../services/utils";
import { gql } from "@apollo/client";
import { CopyToClipboard } from "react-copy-to-clipboard";
import numeral from "numeral";
import { getSwapsData } from "../../../../services/swap";
import { getBuniInfo } from "../../../../services/swap";
import { request } from "../../../../helpers/subgraph";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useHistory } from "react-router-dom";
import { PAIR_OPTIONS, TOKENS } from "../../../../constants";
import { ApolloClient } from "../../../../apolloClient";
import { formatPoolInfo, getContractAddress } from "../../../../helpers";
import Pool from "../../../../services/pool";
enum Trend {
  UP = "up",
  DOWN = "down",
  STABLE = "stable",
}
interface CommonInfoProps {
  token: string;
}
const TransactionTable: React.FC<CommonInfoProps> = ({
  token,
}: CommonInfoProps) => {

  const apolloClient = ApolloClient(token);
  const history = useHistory();
  const address = (token === 'bur' ? process.env.REACT_APP_BUR_ADDRESS : process.env.REACT_APP_BUNI_ADDRESS) || '0x0e7beec376099429b85639eb3abe7cf22694ed49';
  const icon = (token === 'bur' ? BurIcon : PairIcon) || PairIcon;
  const [poolInfo, setPoolInfo] = useState<any>();
  //@ts-ignore
  const getLastDataTable = (token: string) => {
    try {
      apolloClient
        .subscribe({
          query: gql`
            subscription oneNewItem {
              swaps(first: 15, orderBy: timestamp, orderDirection: desc) {
                price
                timestamp
              }
            }
          `,
          variables: {},
        })
        .subscribe({
          next(data:any) {
            const res = data?.data?.swaps || [];
            if (!!res.length) {
              getPriceIncreasePercent(
                token,
                res[0].timestamp,
                res[0].timestamp - 24 * 3600
              );
            }
            // Notify your application with the new arrived data
          },
        });
    } catch (e) {
      console.error("xxxxx", e.message);
    }
  };

  const getPriceIncreasePercentFirstTime = async (token: string) => {
    const res = await getSwapsData(token, 0);
    if (res && res.length) {
      getPriceIncreasePercent(
        token,
        res[0].timestamp,
        res[0].timestamp - 24 * 3600
      );
    }
  };

  async function getPriceIncreasePercent(
    token: string,
    current: number,
    previous: number
  ) {
    const priceUsdNow = await getPriceUsdAtTime(token, current);
    const priceUsdLast24h = (await getPriceUsdAtTime(token, previous)) || 0.03;

    if (!priceUsdNow || !priceUsdLast24h) {
      setPercent(0);
      setIsIncreasing("stable");
      return;
    }
    setPercent((priceUsdNow - priceUsdLast24h) / priceUsdLast24h);
    if (priceUsdNow > priceUsdLast24h) {
      setIsIncreasing("increase");
    } else if (priceUsdNow < priceUsdLast24h) {
      setIsIncreasing("decrease");
    } else {
      setIsIncreasing("stable");
    }
  }
  const [percentChange, setPercent] = useState(0);

  const buniCirculatingSupply =
    process.env.REACT_APP_BUNI_CIRCULATING_SUPPLY || "39415213";
  const burCirculatingSupply =
  process.env.REACT_APP_BUR_CIRCULATING_SUPPLY || "10000000";
  const getLastBuniInfo = () => {
    try {
      apolloClient
        .subscribe({
          query: gql`
            subscription oneNewItem {
              bunis {
                base
                quote
                baseAmount
                quoteAmount
                liquidity
                marketCap
                previousPrice
                previousPriceUsd
                currentPrice
                currentPriceUsd
                volume
                lastVolume
                volumeUsd
                txCount
                holderCount
              }
            }
          `,
          variables: {},
        })
        .subscribe({
          next(data:any) {
            const res = data?.data?.bunis || [];
            if (!!res.length) {
              setBuniInfo(res);
            }
            // Notify your application with the new arrived data
          },
        });
    } catch (e) {
      console.error("xxxxx", e.message);
    }
  };

  const getBunisFirstTime = async (token: string) => {
    const res = await getBuniInfo(token);
    if (res && res.length) {
      setBuniInfo(res);
    }
  };

  const getLastBuniMeta = () => {
    try {
      apolloClient
        .subscribe({
          query: gql`
            subscription getBuniMeta {
              buniMetas {
                id
                holderCount
              }
            }
          `,
          variables: {},
        })
        .subscribe({
          next(data:any) {
            const res = data?.data?.buniMetas || [];
            if (!!res.length) {
              setBuniMeta(res);
            }
            // Notify your application with the new arrived data
          },
        });
    } catch (e) {
      console.error("xxxxx", e.message);
    }
  };

  const getBuniMetas = async (token: string) => {
    const res = await request(token, "getBuniMeta");
    return res && res.buniMetas;
  };

  const getBuniMetasFirstTime = async (token: string) => {
    const res = await getBuniMetas(token);
    if (res && res.length) {
      setBuniMeta(res);
    }
  };

  const subscribeChartUpdated = () => {
    try {
      apolloClient
        .subscribe({
          query: gql`
            subscription oneNewItem {
              hourlyCandles(
                first: 1
                orderBy: timestamp
                orderDirection: desc
              ) {
                id
                timestamp
                volumeUsd
              }
            }
          `,
          variables: {},
        })
        .subscribe({
          next(data:any) {
            const bar = data.data.hourlyCandles[0];

            handleNewChartData(bar);
          },
        });
    } catch (e) {
      console.error("xxxxx", e.message);
    }
  };

  const getHourlyCandles = async (token: string) => {
    const res = await request(token, "getHourlyCandles");
    return res && res.hourlyCandles;
  };

  const getHourlyCandlesFirstTime = async (token: string) => {
    const res = await getHourlyCandles(token);
    if (res && res.length) {
      const bar = res[0];
      handleNewChartData(bar);
    }
  };

  function formatPercentChange(percentChange: number) {
    return numeral(percentChange).format("0.[00]%");
  }

  function isArrowUp(prev: BigNumber, curr: BigNumber) {
    if (prev.lt(curr)) {
      return ArrowUp;
    } else if (prev.gt(curr)) {
      return ArrowDown;
    } else {
      return "-";
    }
  }
  const getTrend = (prev: string, curr: string) => {
    const cPrev = new BigNumber(prev);
    const cCurr = new BigNumber(curr);

    if (cPrev.lt(cCurr)) {
      return Trend.UP;
    }
    if (cPrev.gt(cCurr)) {
      return Trend.DOWN;
    }
    return Trend.STABLE;
  };

  const handleNewChartData = (bar: any) => {
    const current24hChart: any = chart24hStateRef.current;

    if (!current24hChart.isLoaded) {
      return;
    }

    const currentTimestamp = currentTimestampInSec();

    current24hChart.charts = current24hChart.charts.filter((chart: any) => {
      return chart.timestamp + 86400 >= currentTimestamp;
    });

    const existBarIndex = current24hChart.charts.findIndex((chart: any) => {
      return chart.id === bar.id;
    });

    if (existBarIndex >= 0) {
      current24hChart.charts[existBarIndex] = bar;
    } else {
      current24hChart.charts.push(bar);
    }

    setChart24h({ ...current24hChart });
  };
  const [isIncreasing, setIsIncreasing] = useState("stable");
  const [buniInfo, setBuniInfo] = useState<any>();
  const [buniMeta, setBuniMeta] = useState<any>();
  const [chart24h, setChart24h] = useState<any>({
    isLoaded: false,
    charts: [],
  });


  const [volume24h, setVolume24h] = useState<any>();

  const chart24hStateRef = useRef();
  chart24hStateRef.current = chart24h;

  const get24hCharts = async (token: string) => {
    const charts = (await get24hCandleCharts(token)) || [];

    setChart24h({
      isLoaded: true,
      charts,
    });
  };
  useEffect(() => {
    let volume = new BigNumber(0);

    chart24h.charts.forEach((chart: any) => {
      volume = volume.plus(chart.volumeUsd);
    });

    setVolume24h(volume.toString());
  }, [chart24h]);

  useEffect(() => {
    if (buniInfo && buniInfo[0] && token) {
      const currentPrice = buniInfo[0].currentPriceUsd;
      document.title = `${token.toUpperCase()} ${formatNumber(currentPrice, "usd-long")} - Dextools Bunicorn`;
    }
  }, [buniInfo, token]);

  const getPoolInfo = async(token: string) => {
    const poolId = (token === 'bur' ? '0xc304505c16134f9233c8f5cc0ea1ffc0f17e0a12' : '0x969c59e4aa215f377184cea9ca80a9d68a843959') || '0x969c59e4aa215f377184cea9ca80a9d68a843959';
    const bPool = new Pool(poolId);
    const metadata = await bPool.getSubgraphMetadata();
    setPoolInfo(formatPoolInfo(metadata, token));
  }
  useEffect(() => {
    getPriceIncreasePercentFirstTime(token);
    getBunisFirstTime(token);
    getBuniMetasFirstTime(token);
    getHourlyCandlesFirstTime(token);
    get24hCharts(token);
    getLastBuniInfo();
    subscribeChartUpdated();
    getLastDataTable(token);
    getLastBuniMeta();
    getPoolInfo(token);
  }, [token]);
  // console.log('META DATA', poolInfo)

  let defaultOption = PAIR_OPTIONS[1];
  if (token) {
    const option = PAIR_OPTIONS.find(
      (option) => option.value === token.toLowerCase()
    );
    if (option) {
      defaultOption = option;
    }
  }
  const trend = useMemo(() => {
    return (
      (!!buniInfo &&
        buniInfo.length > 0 &&
        isArrowUp(
          new BigNumber(buniInfo[0].previousPriceUsd),
          new BigNumber(buniInfo[0].currentPriceUsd)
        )) ||
      "-"
    );
  }, [buniInfo]);
  return (
    <div className="info-wrapper">
      {!!buniInfo && buniInfo.length > 0 && (
        <>
          <div className="common-info">
            <div className="icon-wrapper">
              <img src={icon} alt="pair-icon" />
            </div>
            <div className="name-wrapper">
              <Dropdown
                className="custom-dropdown"
                options={PAIR_OPTIONS}
                onChange={(option) => {
                  // window.location.replace(`/${option.value}`);
                  //@ts-ignore
                  window.location.replace(`/?coin=${TOKENS[option.value].address}`);
                }}
                value={defaultOption}
                placeholder="Select an option"
              />
              <div className="address-wrapper">
                <div style={{ textTransform: "capitalize" }}>
                  ({token || "Buni"} Token)
                  <a
                    className="token-address"
                    href={getContractAddress(address)}
                    target="_blank"
                  >
                    Token contract: {shortenAddress(address)}
                  </a>
                </div>
                <CopyToClipboard text={`${address}`}>
                  <img className="link-icon" src={LinkIcon} alt="link-icon" />
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="price-info-block">
            <div className="price-wrapper">
              <div className="price-block">
                {trend !== "-" ? <img src={trend} alt="arrow-up" /> : "-"}
                <div
                  className={`price ${getTrend(
                    buniInfo[0].previousPriceUsd,
                    buniInfo[0].currentPriceUsd
                  )}`}
                >
                  {formatNumber(buniInfo[0].currentPriceUsd, "usd-long")}
                </div>
              </div>
              <div className="sub-price">
                <span className={"percent-change " + isIncreasing}>
                  (24h: {formatPercentChange(percentChange)})
                </span>
                &nbsp;
                <span
                  className={`${getTrend(
                    buniInfo[0].previousPriceUsd,
                    buniInfo[0].currentPriceUsd
                  )}`}
                >
                  {formatNumber(buniInfo[0].currentPrice, "long")}{" "}
                  {process.env.REACT_APP_BNB_SYMBOL}
                </span>
              </div>
            </div>
           {poolInfo &&
            <div className="detail-info-wrapper">
              {" "}
              <div className="row">
                <div className="category">Total liquidity</div>
                <div>{formatNumber(poolInfo.liquidity, "usd")}</div>
              </div>
              <div className="row">
                <div className="category">24h Volume</div>
                <div>{formatNumber(volume24h, "usd")}</div>
              </div>
              <div className="row">
                <div className="category">
                  Pooled{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {token || "BUNI"}
                  </span>
                </div>
                <div>{formatNumber(poolInfo.buniToken, "long")}</div>
              </div>
              <div className="row">
                <div className="category">
                  Pooled {process.env.REACT_APP_BNB_SYMBOL}
                </div>
                <div>{formatNumber(poolInfo.wbnbToken, "long")}</div>
              </div>
              <div className="row">
                <div className="category">Total tx</div>
                <div>{poolInfo.swapsCount}</div>
              </div>
              <div className="row">
                <div className="category">Holders</div>
                <div>
                  {!!buniMeta && buniMeta.length > 0
                    ? buniMeta[0].holderCount
                    : 0}
                </div>
              </div>
              <div className="row">
                <div className="category">Market Cap</div>
                {/* {buniInfo[0].marketCap} */}
                <div>
                  {token === 'bur' ? formatNumber(
                    Number.parseInt(burCirculatingSupply) *
                      buniInfo[0].currentPriceUsd,
                    "usd"
                  )  :formatNumber(
                    Number.parseInt(buniCirculatingSupply) *
                      buniInfo[0].currentPriceUsd,
                    "usd"
                  )}
                </div>
              </div>
            </div> }
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default TransactionTable;
