import merge from 'lodash/merge';
import registryKovan from '../libs/generated/registry.kovan.json';
import registryBsc from '../libs/generated/registry.bsc.json';
import homestead from './homestead.json';
import kovan from './kovan.json';
import bsc from './bsc.json';
import kovanFarms from './kovan.farms.json';
import kovanPreStaking from './kovan.pre_staking.json';
import bscFarms from './bsc.farms.json';

const configs = { homestead, kovan, bsc };
configs.kovan = merge(registryKovan, { farms: kovanFarms, prestaking: kovanPreStaking }, configs.kovan);
configs.bsc = merge(registryBsc, { farms: bscFarms, prestaking: kovanPreStaking }, configs.bsc);
const network = process.env.REACT_APP_NETWORK || 'bsc';
// @ts-ignore
const config: Config = configs[network];
config.env = process.env.VUE_APP_ENV || 'staging';

export interface Config {
  env: string;
  network: string;
  chainId: number;
  defaultPrecision: number;
  rpcUrl: string[];
  infuraKey: string[];
  wsUrl: string;
  wsUrl2: string;
  explorer: string;
  exchangeUrl: string;
  helpUrl: string;
  buniPrice: number;
  buniPerBlock: number;
  blockTime: number;
  addresses: {
    weth: string;
    multicall: string;
    masterChef: string;
    vbuni: string;
    buniFarm: string;
    buni: string;
    preStakingFactory: string;
  };
  reserveCurrencies: string[];
  systemCoin: {
    symbol: string;
    name: string;
    wrap: string;
    logo: string;
  };
  kalancer: KalancerConfig;
  kurve: KurveConfig;
  connectors: ConnectorConfig;
  tokens: Record<string, Token>;
  farms: FarmConfig[];
  prestaking: PreStakingConfig[];
  nftMetadataDebugUrl: string;
}

export interface FarmConfig {
  pid: string;
  lpAddresses: string;
  poolAddress: string;
  poolType: string;
}

export interface PreStakingConfig {
  pid: string;
  preStakingAddress: string;
  lpAddress: string;
  poolType: string;
}

export interface KalancerConfig {
  subgraphBackupUrl: string;
  subgraphUrl: string;
  addresses: {
    bFactory: string;
    bActions: string;
    exchangeProxy: string;
    dsProxyRegistry: string;
    proxy: string;
    crpFactory: string;
    poolInfoMulticall: string;
  };
  pair: {
    inputAsset: string;
    outputAsset: string;
  };
}
export interface KurveConfig {
  subgraphUrl: string;
  blockSubGraphUrl: string;
  addresses: {
    factory: string;
    routerV2: string;
  };
  pair: {
    inputAsset: string;
    outputAsset: string;
  };
  whitelistTokens: string[];
  whitelistPairs: string[];
}

export interface ConnectorConfig {
  injected: ConnectorMetadata;
  walletconnect: ConnectorMetadata;
  portis: ConnectorMetadata;
  walletlink: ConnectorMetadata;
  fortmatic: ConnectorMetadata;
  bsc: ConnectorMetadata;
}

export interface Token {
  address: string;
  id: string;
  name: string;
  symbol: string;
  decimals: number;
  precision: number;
  color: string;
  chainId: number;
  hasIcon: boolean;
  logoURI: string;
  forKurve?: boolean;
}

export interface ConnectorMetadata {
  id: string;
  name: string;
  options: any;
}

export interface AssetMetadata {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  logoURI: string | undefined;
  forKurve?: boolean;
}

export default config;
