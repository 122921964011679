import { FallbackProvider, JsonRpcProvider, InfuraProvider } from "@ethersproject/providers";
import config from "../config";

function getKovanProviders() {
  const infuraKeys = config.infuraKey;

  const providers:any = [];
  infuraKeys.forEach((infuraKey, index) => {
    const provider = new InfuraProvider(
      config.network,
      infuraKey,
    );
    const priority = index + 1;
    providers.push({
      provider,
      priority,
      stallTimeout: 1500 + 500 * priority
    });
  });
  return providers;
}

function getBscProviders() {
  const rpcUrls = config.rpcUrl;

  const providers:any = [];
  rpcUrls.forEach((rpcUrl, index) => {
    const provider = new JsonRpcProvider(
      rpcUrl
    );
    const priority = index + 1;
    providers.push({
      provider,
      priority,
      stallTimeout: 1500 + 500 * priority
    });
  });
  return providers;
}

let providers = getBscProviders();
if (config.network === 'kovan') {
  providers = getKovanProviders();
}

const provider = new FallbackProvider(providers,
  1,
);

export default provider;
