import { MakeApiRequest } from '../interfaces/chart/makeApiRequest';

export async function makeApiRequest(
  path: string,
  params: MakeApiRequest,
): Promise<any> {
  if (!params) {
    const symbols = [
      {
        symbol: 'BUNI/BNB',
        baseAsset: 'BUNI',
        quoteAsset: 'BNB',
      },
    ];
    return symbols;
  }
  const requestOption = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  };
  try {
    const response = await fetch(
      `${process.env.API_URL}/api/v1/${path}`,
      requestOption,
    );
    return await response.json();
  } catch (error) {
    throw new Error(`request error: ${error.status}`);
  }
}

export function generateSymbol(
  exchange: string,
  fromSymbol: string,
  toSymbol: string,
): any {
  return {
    symbol: fromSymbol,
    full_name: `${fromSymbol}/${toSymbol}`,
    description: `${fromSymbol}/${toSymbol}`,
    exchange: exchange,
    ticker: `${fromSymbol}/${toSymbol}`,
    type: 'stock',
  };
}

export function parseFullSymbol(fullSymbol: string): any {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }
  return {
    exchange: match[1],
    fromSymbol: match[2],
    toSymbol: match[3],
  };
}
