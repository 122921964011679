import { ITEMS_PER_PAGE } from "../constants";
import { request } from "../helpers/subgraph";
import {currentTimestampInSec} from "../services/utils";

export const getBuniInfo = async (token: string) => {
  const res = await request(token, "getBuniInfo");
  return res && res.bunis;
};

export const getSwapsData = async (token: string, page: number) => {
  const query = {
    swaps: {
      __args: {

        first: ITEMS_PER_PAGE,
        skip: page*ITEMS_PER_PAGE,
        orderBy: "timestamp",
        orderDirection: "desc",
      },
    },
  };
  const res = await request(token,"getSwaps", query);
  return res && res.swaps;
};

export const getPriceUsdAtTime = async (token:string, timestamp: any) => {
  const query = {
    swaps: {
      __args: {
        where: {
          timestamp_lte: timestamp,
        },
      orderBy: "timestamp",
      orderDirection: "desc",
      },
    },
  };
  const res = await request(token, "getSwaps", query);
  return res && res.swaps && !!res.swaps.length && res.swaps[0].priceUsd;
}



export const getCandleChartInfo = async (token: string, startTime: any, endTime: any, resolution:any) => {
  if (resolution === '1D' || resolution === 'D' || resolution >= 60) {
    const query = {
      hourlyCandles: {
        __args: {
          where: {
            timestamp_gt: startTime,
            timestamp_lt: endTime,
          },
          orderBy: "timestamp",
          orderDirection: "desc",
          first: 1000
        },
      },
    };
    const res = await request(token, "getHourlyCandles", query);
    return res && res.hourlyCandles;
  }
  if (resolution === '1W' || resolution === 'W' || resolution === '1M' || resolution === 'M') {
    const query = {
      dailyCandles: {
        __args: {
          where: {
            timestamp_gt: startTime,
            timestamp_lt: endTime,
          },
          orderBy: "timestamp",
          orderDirection: "desc",
          first: 1000
        },
      },
    };
    const res = await request(token, "getDailyCandles", query);
    return res && res.dailyCandles;
  }

  const query = {
    candles: {
      __args: {
        where: {
          timestamp_gt: startTime,
          timestamp_lt: endTime,
        },
        orderBy: "timestamp",
        orderDirection: "desc",
        first: 1000
      },
    },
  };
  const res = await request(token, "getCandles", query);
  return res && res.candles;
};

export const getBiggestSmallerTimestamp = async (token: string, timestamp: any) => {
  const query = {
    candles: {
      __args: {
        first: 1,
        orderBy: 'timestamp',
        orderDirection: 'desc',
        where: {
          timestamp_lt: timestamp,
        },
      },
    },
  };
  const res = await request(token, "getBiggestSmallerTimestamp", query);
  return res && res.candles;
}

export const get24hCandleCharts = async (token: string) => {
  const last24hTimestamp = currentTimestampInSec() - 86400;
  const query = {
    hourlyCandles: {
      __args: {
        where: {
          timestamp_gt: last24hTimestamp
        }
      },
    },
  };
  const res = await request(token, "getHourlyCandles", query);
  return res && res.hourlyCandles;
}
