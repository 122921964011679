import React, { useEffect, useState } from "react";
import BscScan from "../../../../assets/bscscan.svg";
import { getSwapsData } from "../../../../services/swap";
import { shortenAddress, formatNumber } from '../../../../services/utils';
import BigNumber from 'bignumber.js';
import { gql } from '@apollo/client';
import { ITEMS_PER_PAGE } from "../../../../constants";

import './TransactionTable.scss';
import { ApolloClient } from "../../../../apolloClient";
interface TransactionTableProps {
    token: string;
}
const TransactionTable: React.FC<TransactionTableProps> = ({token}: TransactionTableProps) => {
    const apolloClient =  ApolloClient(token);
    const getLastDataTable = () => {
        try {
            apolloClient.subscribe({
                query: gql`
                subscription oneNewItem {
                    swaps(first: 15, orderBy: timestamp, orderDirection: desc) {
                        id
                        type
                        price
                        priceUsd
                        amount
                        value
                        caller
                        timestamp
                        tx
                    }
                }`,
                variables: {}
            }).subscribe({
                next(data:any) {
                    const res = data?.data?.swaps || [];
                    if (!!res.length) {
                        setSwaps(res);
                    }
                    // Notify your application with the new arrived data
                }
            });
        } catch (e) {
            console.error('xxxxx', e.message);
        }
    }

    const [page, setPage] = useState(0);
    const [swaps, setSwaps] = useState<any>([]);
    const getSwaps = async (token: string) => {
        const res = await getSwapsData(token, page)
        if (res && res.length) {
            setSwaps(res);
        }
    }

    function prevPage() {
        if (page <= 0) return;
        setPage(page - 1);
    }
    function nextPage() {
        if (page >= 299) return;
        setPage(page + 1);
    }
    const getEtherscanLink = (str: string, type = "address"): string => {
        return `${process.env.REACT_APP_EXPLORER_URL}/${type}/${str}`;
    };
    const getTxHashFromId = (id: string) => {
        return id.split("-")[0];
    };
    useEffect(() => {
        getSwaps(token);
        getLastDataTable();
    }, [token]);
    useEffect(() => {
        getSwaps(token);
    }, [page, token]);
    return (
        <div className="trading-info-block">
            <div className="header">
                <div className="toggle">Trade History</div>
                <div className="filter">
                    <div>{token.toUpperCase()} (last {(page) * ITEMS_PER_PAGE + 1}-{(page) * ITEMS_PER_PAGE + ITEMS_PER_PAGE}  trades)</div>
                </div>
            </div>
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <td className="date">Date</td>
                            <td>Type</td>
                            <td>Price USD</td>
                            <td>Price {process.env.REACT_APP_BNB_SYMBOL}</td>
                            <td>Amount {token.toUpperCase()}</td>
                            <td>Total {process.env.REACT_APP_BNB_SYMBOL}</td>
                            <td>Maker</td>
                            <td className="other">Others</td>
                        </tr>
                    </thead>
                    <tbody id="theTable">
                        {!!swaps.length &&
                            swaps.map((swap: any, index: number) => {

                                return (
                                    <tr key={index}>
                                        <td className="date">
                                            {new Date(swap.timestamp * 1000).toLocaleString()}
                                        </td>
                                        <td className={swap.type}>{swap.type}</td>
                                        <td className={swap.type}>{formatNumber(new BigNumber(swap.priceUsd), 'usd-long')}</td>
                                        <td className={swap.type}>{formatNumber(new BigNumber(swap.price), 'long')}</td>
                                        <td className={swap.type}>{formatNumber(swap.amount, 'long')}</td>
                                        <td className={swap.type}>{formatNumber(swap.value,'long')}</td>
                                        <td className="address">
                                            <a
                                                href={getEtherscanLink(getTxHashFromId(swap.caller))}
                                                target="_blank"
                                                className="address"
                                            >
                                                {shortenAddress(swap.caller)}
                                            </a>
                                        </td>
                                        <td className="other">
                                            <a href={getEtherscanLink(getTxHashFromId(swap.tx), "tx")} target="_blank">
                                                <img src={BscScan} className="bsc-scan-icon" alt="bsc-scan-icon" />
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button className={`pageButton ${page === 0 ? 'disable' : ''}`} onClick={prevPage}>Back</button>
                <button className={`pageButton ${page >= 299 ? 'disable' : ''}`} onClick={nextPage}>Next</button>
            </div>
        </div>
    );
};

export default TransactionTable;
