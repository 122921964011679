export const CHART_CONTAINER_ID = {
  StellarChart: "tv_chart_container",
  EvrynetPool: "tv_chart_container_liqui_pool",
};
export const ITEMS_PER_PAGE = 15;
export const PAIR_OPTIONS = [
  { value: "buni", label: "BUNI/WBNB"},
  { value: "bur", label: "BUR/WBNB" },
];
export const TOKENS = {
  'buni': {
    address: process.env.REACT_APP_BUNI_ADDRESS,
  },
  'bur': {
    address: process.env.REACT_APP_BUR_ADDRESS,
  }
}