import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import { fullScreen, tickSuccess, zoomOut } from '../../../assets/icon';
import ArrowDown from '../../../assets/ArrowDown';
import indicatorSvg from '../../../assets/indicator-light.svg';
import { CImage } from '../../../components/Image';
import {
  CHART_TYPE,
  intervarMasterArr,
} from '../../../constants/chart-interval';
import { IntervalObj } from '../../../constants/intervalObject';
import ItemNavBar from './ItemNavBar';
import style from '../styles/TradingView.module.scss';
import { CandleInfoType } from './TradingView/TradingView';

interface Props {
  [key: string]: any;
  containerId?: any;
  isFullScreen: boolean;
}

const NabarTradingView: React.FC<Props> = (props) => {
  const cx = classNames.bind(style);
  const [isShowGroupInterval, setIsShowGroupInterval] =
    useState<boolean>(false);
  const [isShowChartType, setIsShowChartType] = useState<boolean>(false);
  const [chartTypeObj, setChartTypeObj] = useState<any>(CHART_TYPE[1]);
  const [isEditBtn, setIsEditBtn] = useState<boolean>(true);
  const [intervalList, setIntervalList] = useState<Array<IntervalObj>>([]);
  const defaultIntervalList = ['Time', '15m', '1h', '4h', '1D', '1W'];
  useEffect(() => {
    intervarMasterArr.map((row) => {
      row.row.map((item) => {
        if (defaultIntervalList.includes(item.key)) {
          item.status = true;
          setIntervalList((intervalArr) => [...intervalArr, item]);
        }
      });
    });
  }, []);
  const useComponentVisible = (initialIsVisible: any) => {
    const [isCptVisible, setIsCptVisible] = useState(initialIsVisible);
    const ref = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsCptVisible(false);
        if (isEditBtn) {
          setIsShowGroupInterval(false);
        }
        setIsShowChartType(false);
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    });

    return { ref, isCptVisible, setIsCptVisible };
  };
  const { ref } = useComponentVisible(true);

  const setInterval = (param: string) => {
    setIsShowGroupInterval(false);
    props.onSelectInterval(param);
  };
  const showOrHideGroupItv = () => {
    setIsShowGroupInterval(!isShowGroupInterval);
  };

  const openIndicatorPopup = () => {
    props.openIndicatorPopup();
  };

  const showOrHideChartType = () => {
    setIsShowChartType(!isShowChartType);
  };

  const isEditOrSave = () => {
    setIsEditBtn(!isEditBtn);
    if (!isEditBtn) {
      setIsShowGroupInterval(!isShowGroupInterval);
    }
  };

  const setChartType = (chartType: number) => {
    props.setChartType(chartType);
    setIsShowChartType(!setIsShowChartType);
  };
  const setIntervalToList = (intervalObj: any) => {
    intervarMasterArr.map((row) => {
      row.row.map((item) => {
        if (item.key === intervalObj.key) {
          item.status = !item.status;
          if (item.status) {
            setIntervalList((intervalArr) => [...intervalArr, item]);
          } else {
            setIntervalList(
              intervalList.filter((item) => item.key !== intervalObj.key),
            );
          }
        }
      });
    });
  };

  return (
    <div>

      <div className={cx('navbar-tradingview')}>
        <div
          className={
            cx('navbar-tradingview__left')
          }
        >
          {intervalList.slice(0, 10).map((interval, idx) => {
            return (
              <ItemNavBar
                content={interval.key}
                onClick={() => setInterval(interval.value)}
                key={idx}
                active={interval.value === props.currentInterval}
              />
            );
          })}
          <div className={cx('parent-position')}>
            <ItemNavBar
              content={<ArrowDown pathFill="#A0A3BD" />}
              onClick={() => showOrHideGroupItv()}
            />
            <div className={cx('group-interval-parent')}>
              {isShowGroupInterval ? (
                <div className={cx('group-interval')} ref={ref}>
                  <div className={cx('group-interval-header')}>
                    <div>Select interval</div>
                    <div
                      onClick={() => isEditOrSave()}
                      className={!isEditBtn ? cx('btn-edit') : cx('')}
                    >
                      {isEditBtn ? 'Edit' : 'Save'}
                    </div>
                  </div>
                  {intervarMasterArr.map((row, idx) => {
                    return (
                      <div
                        className={cx(
                          'group-interval-line',
                          'group-interval-line-config',
                        )}
                        key={idx}
                      >
                        {row.row.map((it) => {
                          return (
                            <div
                              key={it.key}
                              className={cx('interval-button')}
                              onClick={() =>
                                !isEditBtn
                                  ? setIntervalToList(it)
                                  : setInterval(it.value)
                              }
                            >
                              <div
                                className={
                                  it.status
                                    ? cx('interval-btn-active')
                                    : cx('')
                                }
                              >
                                {it.key}
                              </div>
                              {!isEditBtn && it.status ? (
                                <img
                                  src={tickSuccess}
                                  alt="Tick"
                                  className={cx('interval-img')}
                                />
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
          <ItemNavBar
            content={
              <CImage
                src={indicatorSvg}
                width={16}
                height={16}
                shape="square"
              />
            }
            onClick={() => openIndicatorPopup()}
          />
          <div className={cx('parent-position')}>
            <ItemNavBar
              content={
                <CImage
                  src={chartTypeObj.src_type}
                  width={16}
                  height={16}
                  shape="square"
                />
              }
              onClick={() => showOrHideChartType()}
            />
            <div className={cx('chart-stype-popup')}>
              {isShowChartType ? (
                <div className={cx('chart-type-parent')} ref={ref}>
                  {CHART_TYPE.map((item) => {
                    return (
                      <div
                        onClick={() => { setChartType(item.type_number); setChartTypeObj(item); }}
                        key={item.type_number}
                        className={cx('chart-type-child')}
                      >
                        <img src={item.src_type} alt="Bars Chart" />{' '}
                        <div>
                          {item.type_name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
          <div className={cx('parent-position')}>
            <ItemNavBar content={<div className={cx('info-type')}>{props.candleInfoType}</div>} onClick={props.setCandleInfoType} />
          </div>
        </div>
        <div className={cx('navbar-tradingview__right')}>
          <ItemNavBar
            content={
              <img
                src={!props.isFullScreen ? fullScreen : zoomOut}
                alt="Full screen"
                className={cx('full-screen-img')}
                onClick={props.handleFullscreen}
              />
            }

          />
        </div>
      </div>

    </div>
  );
};

export default NabarTradingView;
