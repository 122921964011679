import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/HomePage";

const App: React.FC<any> = () => {
  return (
    <>
      <React.Suspense fallback={<div>....Loading</div>}>
        <Switch>
          <Route path="/?coin=:token" component={Home} />
          <Route path="/" component={Home} />
        </Switch>
      </React.Suspense>
    </>
  );
};

export default App;
