import { generateSymbol, makeApiRequest } from '../../../../helpers/ChartHelper';

const channelToSubscription = new Map();

const configurationData = {
  supports_search: true,
  supports_marks: true,
  exchanges: [
    { value: '', name: 'All Exchanges', desc: '' },
    { value: 'XETRA', name: 'XETRA', desc: 'XETRA' },
    { value: 'NSE', name: 'NSE', desc: 'NSE' },
  ],
  symbols_types: [
    { name: 'All types', value: '' },
    { name: 'Stock', value: 'stock' },
    { name: 'Index', value: 'index' },
  ],
  supported_resolutions: [
    '1',
    '3',
    '5',
    '15',
    '30',
    '60',
    '120',
    '240',
    '360',
    '480',
    '720',
    '480',
    '1D',
    '3D',
    '1W',
    '1M',
  ],
};
function convertResolution(resolution) {
  let interval = {
    '1m': '60',
    '3m': '180',
    '5m': '300',
    '15m': '900',
    '30m': '1800',
    '1h': '3600',
    '1D': '86400',
    '2D': '172800',
    '1W': '604800',
    '3W': '1814400',
    '1M': '54432000',
    '6M': '326592000',
  }[resolution];
  return interval;
}

export async function getAllSymbols() {
  const data = await makeApiRequest('v3/exchangeInfo', null);
  let allSymbols = [];

  data.map((symbol) => {
    const symbolAfterGenerate = generateSymbol(
      symbol.symbol,
      symbol.baseAsset,
      symbol.quoteAsset,
    );
    allSymbols.push(symbolAfterGenerate);
  });
  return allSymbols;
}

export default {
  onReady: (callback) => {
    setTimeout(() => callback(configurationData));
  },

  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback,
  ) => {
    const symbols = await getAllSymbols();
    const newSymbols = symbols.filter((symbol) =>
      symbol.full_name.includes(userInput),
    );
    onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
  ) => {
    const symbols = await getAllSymbols();
    const symbolItem = symbols.find((sym) => sym.full_name === symbolName);
    if (!symbolItem) {
      onResolveErrorCallback('cannot resolve symbol');
      return;
    }
    const symbolInfo = {
      ticker: symbolItem.ticker,
      name: symbolItem.full_name,
      description: symbolItem.description,
      type: symbolItem.type,
      session: '24x7',
      timezone: 'Etc/UTC',
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: 1000,
      has_intraday: true,
      has_weekly_and_monthly: true,
      intraday_multipliers: ['1', '60'],
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 0,
    };

    onSymbolResolvedCallback(symbolInfo);
  },

  getBars: async (
    symbolInfo,
    resolution,
    from,
    to,
    onHistoryCallback,
    onErrorCallback,
    firstDataRequest,
  ) => {},

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback,
  ) => {
    const interval = convertResolution(resolution);
    if (channelToSubscription.has(subscribeUID)) {
    }
  },

  unsubscribeBars: (subscriberUID) => {},
};
